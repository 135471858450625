import { usePageContext } from 'vike-react/usePageContext'
import { localeDefault } from 'src/locales/locales'
import { Link } from '@chakra-ui/react'
import { forwardRef } from 'react'

const LocaleLink = forwardRef(({ href, ...props }, ref) => {
  const pageContext = usePageContext()
  const locale = pageContext.locale

  if (locale !== localeDefault) {
    if (!href.startsWith("https")) { // safeguard
      href = '/' + locale + href
    }
  }
  return <Link ref={ref} href={href} {...props} />
})

export default LocaleLink;
